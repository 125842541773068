<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('jobs')" :is-filter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('jobs')" :is-filter="false">
                </HeaderMobile>
            </template>
            <div class="row data-form">
                <div class="col-12">
                    <ValidationObserver ref="form">
                        <div class="row">
                            <div class="col-12 col-sm-8">
                                <ValidationProvider name="job" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('process')">
                                        <multi-selectbox :allow-empty="false" :multiple="false"
                                                         :validate-error="errors[0]"
                                                         :options="jobOptions" v-model="form.job"></multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showSemester">
                                        <ValidationProvider name="semester_id" rules="" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('semester')">
                                                <semesters-selectbox :multiple="false" :validate-error="errors[0]"
                                                                     :setActive="true" v-model="form.semester_id">
                                                </semesters-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                         v-if="['job_prep_student_period_status_create'].includes(form.job)">
                                        <ValidationProvider name="semester_id" rules="required" v-slot="{ errors }">
                                            <b-form-group :label="$t('academic_year')">
                                                <academic-years-selectbox :validate-error="errors[0]"
                                                                          v-model="form.academic_year"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                         v-if="['job_prep_student_period_status_create'].includes(form.job)">
                                        <ValidationProvider name="semester_id" rules="required" v-slot="{ errors }">
                                            <b-form-group :label="$t('module')">
                                                <module-selectbox valueType="id" :academic_year="form.academic_year"
                                                                  :validate-error="errors[0]" v-model="form.module_id"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6 col-xs-12" v-if="showNumbers">
                                <ValidationProvider name="number" rules="" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('student_numbers')">
                                        <lined-textarea v-model="form.number" :nowrap="false" :disabled="false"
                                                        :styles="{ height: '15em', resize: 'both' }"
                                                        :validateError="errors[0]">
                                        </lined-textarea>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>


                            <div class="col-12 col-md-6"
                                 v-if="['auto_course_registration', 'auto_repeat_course_registration'].includes(form.job)">
                                <ValidationObserver ref="facultyValid">
                                    <ValidationProvider name="faculty_code" rules="" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('faculty')">
                                            <faculty-selectbox v-model="faculty_code" :validate-error="errors[0]"/>
                                        </b-form-group>
                                    </ValidationProvider>
                                </ValidationObserver>
                            </div>

                            <div class="col-12 col-md-8"
                                 v-if="['auto_course_registration', 'auto_repeat_course_registration','job_prep_student_period_status_create','bulk_address_update','registrations_bulk_delete'].includes(form.job)">
                                <ValidationProvider name="student_numbers" rules="" v-slot="{ errors }">
                                    <b-form-group :label="$t('student_numbers')">
                                        <lined-textarea v-model="student_numbers"
                                                        :nowrap="false"
                                                        :disabled="false"
                                                        :styles="{ height: '124px' }" :validate-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <b-button @click="start" label="start_process"></b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import {ValidationObserver, ValidationProvider} from "vee-validate"
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"
import JobService from "@/services/JobService";
import LinedTextarea from "../../../components/elements/LinedTextarea"
import SemestersSelectbox from "../../../components/interactive-fields/SemestersSelectbox"
import qs from "qs"
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox"
import ModuleSelectbox from "@/components/interactive-fields/ModuleSelectbox"

export default {
    name: "JobsStart",
    components: {
        SemestersSelectbox,
        LinedTextarea,
        MultiSelectbox,
        AppLayout,
        ValidationProvider,
        ValidationObserver,
        Header,
        HeaderMobile,
        AcademicYearsSelectbox,
        ModuleSelectbox
    },
    metaInfo() {
        return {
            title: this.$t('jobs')
        }
    },
    data() {
        return {
            showSemester: false,
            showNumbers: false,
            faculty_code: null,
            student_numbers: null,
            form: {},
            jobOptions: [
                {value: 'calculate_extra_graduate_courses', text: this.$t('calculate_extra_graduate_courses')},
                {value: 'auto_course_registration', text: this.$t('auto_course_registration')},
                {value: 'auto_repeat_course_registration', text: this.$t('auto_repeat_course_registration')},
                {value: 'auto_irregular_course_registration', text: this.$t('auto_irregular_course_registration')},
                {value: 'create_student_semesters', text: this.$t('create_student_semesters')},
                {value: 'assign_doesnt_have_payment_approval', text: this.$t('assign_doesnt_have_payment_approval')},
                {
                    value: 'assign_doesnt_have_course_registration',
                    text: this.$t('assign_doesnt_have_course_registration')
                },
                {
                    value: 'job_prep_student_period_status_create',
                    text: this.$t('job_prep_student_period_status_create')
                },
                {value: 'bulk_address_update', text: this.$t('bulk_address_update')},
                {
                    value: 'foreign_student_community_internship_assignment',
                    text: this.$t('foreign_student_community_internship_assignment')
                },
                {
                    value: 'registrations_bulk_delete',
                    text: this.$t('registrations_bulk_delete')
                },
                {
                    value: 'course_drop_process',
                    text: this.$t('course_drop_process')
                }
            ],
        }
    },
    methods: {
        async start() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                let config = {params: this.form};
                let oldNumber = this.form.number;
                if (oldNumber) {
                    config.params.number = config.params.number.split(/\n/);
                }

                this.$swal.fire({
                    text: this.$t('are_you_sure_to_start_process'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            if (this.form.job == 'calculate_extra_graduate_courses') {
                                JobService.calculateExtraGraduateCourses().then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            } else if (this.form.job == 'auto_course_registration') {
                                const config = {
                                    params: {
                                        faculty_code: this.faculty_code,
                                        student_number: this.student_numbers == null ? null : this.student_numbers.replace(/\n/g, ',').split(',')
                                    },
                                    paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                                };
                                JobService.autoCourseRegistration(config).then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            } else if (this.form.job == 'auto_repeat_course_registration') {
                                const config = {
                                    params: {
                                        faculty_code: this.faculty_code,
                                        student_number:this.student_numbers!=null?this.student_numbers.replace(/\n/g, ','):null                                    },
                                    paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                                };
                                JobService.autoRepeatCourseRegistration(config).then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            } else if (this.form.job == 'auto_irregular_course_registration') {
                                JobService.autoIrregularCourseRegistration().then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            } else if (this.form.job == 'create_student_semesters') {
                                JobService.createStudentSemesters(this.form.semester_id).then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            } else if (this.form.job == 'assign_doesnt_have_payment_approval') {
                                JobService.assignDoesntHavePaymentApproval(this.form.semester_id).then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            } else if (this.form.job == 'assign_doesnt_have_course_registration') {
                                JobService.assignDoesntHaveCourseRegistration(this.form.semester_id).then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            } else if (this.form.job == 'job_prep_student_period_status_create') {
                                let formData = {
                                    module_id: this.form.module_id,
                                    student_number: this.student_numbers == null ? null : this.student_numbers.replace(/\n/g, ',').split(',')
                                }

                                JobService.prepStudentsSemesterStatusUpdate(formData).then(response => {
                                    this.callback(response)
                                }).catch(e => {
                                    this.showErrors(e, this.$refs.form)
                                })
                            } else if (this.form.job == 'bulk_address_update') {
                                let formData = {
                                    student_numbers: this.student_numbers == null ? null : this.student_numbers.replace(/\n/g, ',').split(',')
                                }
                                JobService.bulkAddressUpdate(formData).then(response => {
                                    this.callback(response)
                                }).catch(e => {
                                    this.showErrors(e, this.$refs.form)
                                })
                            } else if (this.form.job == 'foreign_student_community_internship_assignment') {
                                let formData = {
                                    student_numbers: this.student_numbers == null ? null : this.student_numbers.replace(/\n/g, ',').split(',')
                                }
                                JobService.foreignStudentCommunityInternshipAssignment(formData).then(response => {
                                    this.callback(response)
                                }).catch(e => {
                                    this.showErrors(e, this.$refs.form)
                                })
                            } else if(this.form.job == 'registrations_bulk_delete'){
                                let formData = {
                                    student_number: this.student_numbers == null ? null : this.student_numbers.replace(/\n/g, ',').split(',')
                                }
                                JobService.registrationsBulkDelete(formData).then(response => {
                                    this._downloadFile(response, 'registrations_bulk_delete.xlsx')
                                }).catch(e => {
                                    this.showErrors(e, this.$refs.form)
                                })
                            }
                            else if(this.form.job == 'course_drop_process'){
                                JobService.courseDropCommand().then(response => {
                                    this.callback(response)
                                }).catch(e => {
                                    this.showErrors(e, this.$refs.form)
                                })
                            }
                            this.faculty_code = null
                            this.form.number = oldNumber;
                        }
                    })

            }
        },
        callback(response) {
            if (response.data.data.log_id) {
                this.$toast.success(this.$t(response.data.message));
                this.$router.push('/job/logs/detail/' + response.data.data.log_id)
            } else if (response.data.data.job_id) {
                if (response.data.message) {
                    this.$toast.success(this.$t(response.data.message));
                } else {
                    this.$toast.success(this.$t('proccess_success'));
                }

                this.$router.push('/job/logs/detail/' + response.data.data.job_id)
            }
        }
    },
    watch: {
        'form.job'(newVal) {

            this.showSemester = false;
            this.showNumbers = false;

            if (newVal == 'create_student_semesters') {
                this.showSemester = true;
            } else if (newVal == 'assign_doesnt_have_payment_approval') {
                this.showSemester = true;
            } else if (newVal == 'assign_doesnt_have_course_registration') {
                this.showSemester = true;
            } else if (newVal == 'job_prep_student_period_status_create') {

            } else if(newVal == 'registrations_bulk_delete'){

            }

        }
    }
};
</script>

